<template>
  <section class="pollViewManagmentSection">
    <div class="pollViewManagment" v-if="analizeRoute">
      <div class="pollViewManagment__header">
        <h1 class="pollViewManagment__title">encuestas</h1>
        <div class="pollViewManagment__header--create">
          <div class="pollViewManagment__header--btnCreate" @click="createPoll">
            <iconic name="plus"></iconic>
            <span class="tooltiptext">Crear encuesta</span>
          </div>
        </div>
      </div>
      <Card v-for="(poll, index) in polls.polls" :key="index" :poll="poll" />
    </div>
    <router-view></router-view>
  </section>
</template>
<script>
  export default {
    data() {
      return {
        polls: [],
      };
    },

    components: {
      Card: () => import("./CarPoll.vue"),
    },
    computed: {
      analizeRoute() {
        return this.$route.path === "/polls" ? true : false;
      },
    },
    methods: {
      createPoll() {
        this.$router.push("/polls/createpoll");
      },
      async getPolls() {
        try {
          this.polls = await this.$store.dispatch("polls/getPoll");
        } catch (error) {
          console.error("Error en getPolls:", error);
          throw error; // Lanza el error para que pueda ser capturado por el watcher
        }
      },
    },
    beforeMount() {
      this.$nextTick(() => {
        this.getPolls();
      });
    },
  };
</script>
<style lang="scss">
  .pollViewManagmentSection {
    max-width: 1480px;
    width: 100%;
    padding-top: 50px;
  }
  .pollViewManagment {
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      &--create {
        position: relative;
        padding: 10px 0 0 10px;
      }
      &--btnCreate {
        width: 30px;
        height: 30px;
        color: #fff;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        background-color: $primary_color;
        .tooltiptext {
          position: absolute;
          background-color: #000;
          opacity: 0.8;
          width: 135px;
          top: 0px;
          left: 40px;
          padding: 3px 0 3px 5px;
          border-radius: 6px;
          visibility: hidden;
          &::after {
            content: "";
            position: absolute;
            top: 8px;
            left: -4px;
            margin-left: -10px;
            border-width: 7px;
            border-style: solid;
            border-color: transparent #000 transparent transparent;
          }
        }
        :nth-child(1) {
          margin-left: 3.7px;
          margin-top: 3.5px;
        }
        &:hover {
          transition: all 0.3s ease-in-out;
          background: $primary_color;
          transform: scale(1.05);
          .tooltiptext {
            visibility: visible;
          }
        }
      }
    }
    &__title {
      font-size: 32px;
    }
  }
</style>
